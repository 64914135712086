import '@css/app.scss'

import {VerhalenSlider} from "./components/VerhalenSlider";
import {Slider} from "./components/Slider";
import {MobileOnlySlider} from "./components/MobileOnlySlider";

// Check if the environment is development
const isDevelopment = process.env.NODE_ENV === 'dev';
// Override console.log to only log in development
if (!isDevelopment) {
    console.log = function() {};
}

$( document ).ready(function() {
    VerhalenSlider();
    Slider();
    MobileOnlySlider();
});

